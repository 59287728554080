import React from "react";

import { Layout, PortfolioNav, PortfolioHeader } from "../../components";

import locales from "../../constants";

const SpokojWGlowie = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Five Seasons",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/five-seasons_top_back.jpg"),
      }}
      header={{
        background:
          "linear-gradient( 45deg, #E9C680, #DEBA72, #CBA75F, #D5AD5D, #E9C67F )",
        icons: "#e9c679",
        navClass: "five-seasons__nav",
        ogImage: require("../../assets/img/portfolio/five-seasons_top_back.jpg"),
      }}
      // langSwitch={{
      //   langKey: "en",
      //   langSlug: "/en/projects/tourism-authority-of-thailand/",
      // }}
    >
      <PortfolioHeader name="five-seasons" height="260" />
      <div className="five-seasons">
        <section
          className="container-fluid portfolio-intro five-seasons-section-2"
          id="info"
        >
          <div className="row">
            <div className="col-md-6">
              <div className="inner">
                <h1>Five Seasons</h1>
                <ul>
                  <li>Strona internetowa</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="inner inner--divider">
                <p>
                  Five Seasons to spółka zajmująca się budową obiektów
                  hotelowo-apartamentowych oraz ich zarządzaniem. Zespół 5s to
                  niespotykane na rynku nieruchomości połączenie kompetencji w
                  obszarze analityki biznesowej oraz zarządzania, dzięki czemu
                  realizowane przez spółkę inwestycje są bardzo wysoko oceniane
                  przez rynek, inwestorów, a finalnie przez gości odwiedzających
                  obiekty należące do grupy lub przez nią zarządzane.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="five-seasons-section-3">
          <div className="wrapper">
            <div className="laptop_wrapper">
              <div className="img_wrapper">
                <div>
                  <img
                    src={require("../../assets/img/portfolio/five-seasons-full-page.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="inner">
              <p>
                Z zespołem Five Seasons pracujemy od lat. Naszym pierwszym
                wspólnym projektem były działania pre-openingowe i openingowe
                dla należących do grupy hoteli{" "}
                <a
                  href="https://when.pl/case-study/blue-mountain-resort"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <u>Blue Mountain Resort</u>
                </a>{" "}
                oraz{" "}
                <a
                  href="https://adream.pl/projekty/green-mountain-resort"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <u>Green Mountain Resort</u>
                </a>
                . Teraz naszym zadaniem było zaprojektowanie serwisu, który
                agregowałby wszystkie inwestycje zrealizowane przez 5s w jednym
                miejscu oraz szerzej przedstawiał markę i dokonania marki Five
                Seasons.
              </p>
            </div>
          </div>
        </section>
        <section className="five-seasons-section-4">
          <img
            src={require("../../assets/img/portfolio/five-seasons-phone.png")}
            alt=""
          />
          <img
            src={require("../../assets/img/portfolio/five-seasons-ipad.png")}
            alt=""
          />
        </section>
        <section className="five-seasons-section-5">
          <div className="left">
            <img
              src={require("../../assets/img/portfolio/five-seasons-main_1.png")}
              alt=""
            />
          </div>
          <div className="right">
            <img
              src={require("../../assets/img/portfolio/five-seasons-main_2.png")}
              alt=""
            />
          </div>
        </section>
      </div>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};

export default SpokojWGlowie;
